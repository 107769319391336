<template>
<div class="p-2">
  <b-card>
    <b-form-group label="Опорные Системы">
      <b-row>
        <b-col>
          <b-dropdown block>
            <template #button-content>
              <b-icon icon="tag-fill"></b-icon> Выбрать Систему
            </template>
            <b-form-input v-model="search" size="sm" placeholder="поиск..."
                          debounce="500" v-on:update="refresh">
            </b-form-input>
            <b-form-checkbox v-model="populatedOnly" size="sm">Только населенные</b-form-checkbox>
            <b-dropdown-divider></b-dropdown-divider>
            <div class="ddcontainer">
              <b-dropdown-group header="Избранное" v-if="favor.length > 0">
                <b-dropdown-item v-for="opt in favor" :key="opt.id"
                                 @click="onOptionClick(opt.id)">
                  <em>{{opt.name}}</em>
                </b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider v-if="favor.length > 0"></b-dropdown-divider>
              <b-dropdown-item v-for="opt in options" :key="opt.id"
                               @click="onOptionClick(opt.id)">
                {{ opt.name }}
              </b-dropdown-item>
              <b-dropdown-text v-if="options.length === 0">
                Список пуст
              </b-dropdown-text>
            </div>
          </b-dropdown>
        </b-col>
        <b-col>
          <!-- <ul v-if="tags.length > 0" class="mb-2"> -->
          <!--   <li v-for="tag in tags" :key="tag"> -->
          <!--     <b-form-tag @remove="removeTag(tag.id)" :title="tag" variant="info"> -->
          <!--       {{ tag.name }}  {{ tag.distances }} -->
          <!--     </b-form-tag> -->
          <!--   </li> -->
          <!-- </ul> -->
          <b-row align-h="start" v-for="tag in tags" :key="tag">
            <b-col>
              <b-form-tag @remove="removeTag(tag.id)" :title="tag" variant="info">
                {{ tag.name }}
              </b-form-tag>
            </b-col>
            <b-col>
              {{ tag.distances }}
            </b-col>
          </b-row>
          <!-- <b-table-simple borderless> -->
          <!--     <b-tbody> -->
          <!--       <b-tr v-for="tag in tags" :key="tag"> -->
          <!--         <b-td> -->
          <!--           <b-form-tag @remove="removeTag(tag.id)" :title="tag" variant="info"> -->
          <!--             {{ tag.name }} -->
          <!--           </b-form-tag> -->
          <!--         </b-td> -->
          <!--         <b-td v-for="dist,idx in tag.distances" :key="idx"> {{ dist }} </b-td> -->
          <!--       </b-tr> -->
          <!--     </b-tbody> -->
          <!-- </b-table-simple> -->
        </b-col>
    </b-form-group>
    
    <b-row>
      <b-col>
        <b-form-group label="Расстояние до опорной системы, макс.">
          <b-form-input v-model="distance" type="number" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Население">
          <b-form-select v-model="populated">
            <b-form-select-option :value="null">Не важно</b-form-select-option>
            <b-form-select-option value="yes">Населена</b-form-select-option>
            <b-form-select-option value="no">Без населения</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    
    <b-row>
      <b-col>
        <b-form-group label="Тип Звезды">
          <b-form-select v-model="subtype">
            <b-form-select-option :value="null">Не важно</b-form-select-option>
            <b-form-select-option value="Blue-White">Бело-голубая</b-form-select-option>
            <b-form-select-option value="^F">Белая</b-form-select-option>
            <b-form-select-option value="White-Yellow">Бело-Желтая</b-form-select-option>
            <b-form-select-option value="Yellow-Orange">Желто-Оранжевая</b-form-select-option>
            <b-form-select-option value="Brown">Коричневый карлик</b-form-select-option>
            <b-form-select-option value="Red">Красный карлик</b-form-select-option>
            <b-form-select-option value="White Dwarf">Белый карлик</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Планеты">
          <b-form-select v-model="has_planets">
            <b-form-select-option :value="null">Не важно</b-form-select-option>
            <b-form-select-option value="firstline">Есть планеты первой линии</b-form-select-option>
            <b-form-select-option value="starfirst">Только звезда на первой линии</b-form-select-option>
            <b-form-select-option value="starsonly">Только звезды</b-form-select-option>
            <b-form-select-option value="alonestar">Только одна звезда</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col v-show="has_planets === null || has_planets == 'firstline' || has_planets == 'starfirst'">
        <div v-show="populated === null || populated == 'yes'">
          <b-form-group label="Станция">
            <b-form-select v-model="station">
              <b-form-select-option :value="null">Не важно</b-form-select-option>
              <b-form-select-option value="bigspace">Большая космическая станция</b-form-select-option>
              <b-form-select-option value="space">Любая космическая станция</b-form-select-option>
              <b-form-select-option value="big">Любая с большой площадкой</b-form-select-option>
              <b-form-select-option value="any">Любая</b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Дистанция до звезды">
            <b-form-input v-model="distance2star" type="number"></b-form-input>
          </b-form-group>
          <b-form-group label="Службы">
            <b-form-checkbox-group v-model="services">
              <b-form-checkbox value="shipyard" size="sm">Верфь</b-form-checkbox>
              <b-form-checkbox value="outfitting" size="sm">Снаряжение</b-form-checkbox>
              <b-form-checkbox value="materialtrader" size="sm">Меняла</b-form-checkbox>
              <b-form-checkbox value="techBroker" size="sm">Техноброкер</b-form-checkbox>
              <b-form-checkbox value="facilitator" size="sm">Юрист</b-form-checkbox>
              <b-form-checkbox value="blackmarket" size="sm">Черный рынок</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">        
      <b-button variant="outline-success" :disabled="busy" @click="doSearch">Найти</b-button>
    </b-overlay>
  </b-card>
  
  <b-table :items="result" :fields="result_fields" bordered small striped responsive hover head-variant="dark" 
           @row-clicked="onClick">
    <template #cell(name)="data">
      <b-link :to="`/system/${data.item.system_id}`">{{data.item.name}}</b-link>
      <span>
        <b-button size="sm" variant="link" class="ml-2 p-0" 
                  @click="copyClipboard(data.item.name)">
          <b-icon icon="stickies"></b-icon>
        </b-button>
      </span>
    </template>
    <template #row-details="row">
      <b-card no-body>
        <b-table-simple small responsive caption-top>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>Принадлежность</b-th>
              <b-th>Правительство</b-th>
              <b-th>Фракция</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>{{ row.item.allegiance }}</b-td>
              <b-td>{{ row.item.government }}</b-td>
              <b-td>{{ row.item.faction }}</b-td>
            </b-tr>
          </b-tbody>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>Экономика</b-th>
              <b-th>Политика</b-th>
              <b-th>Население</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>{{ row.item.economy }}</b-td>
              <b-td>{{ row.item.power }}</b-td>
              <b-td>{{ row.item.population }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        
        <b-table-simple striped small responsive caption-top v-if=row.item.distances>
          <caption>Дистанции</caption>
          <b-tbody>
            <b-tr v-for="item,idx in row.item.distances" :key="idx">
              <b-td>{{ item.name }}</b-td>
              <b-td>{{ item.distance }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <b-table-simple striped small responsive caption-top v-if=row.item.stations>
          <caption>Станции</caption>
          <b-tbody>
            <b-tr v-for="st,idx in row.item.stations" :key="idx">
              <b-td>{{ st.name }}</b-td>
              <b-td>{{ st.type }}</b-td>
              <b-td>{{ st.economies }}</b-td>
              <b-td>{{ st.landing_pad }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        
      </b-card>
    </template>
  </b-table>
  
</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'ParkingSearch',
    inject: ['getUserInfo'],
    data() {
        return {
            systems: [],
            tags: [],
            options: [],
            favor: [],
            distance: 10,
            populated: null,
            has_planets: null,
            subtype: null,
            station: null,
            services: [],
            distance2star: null,
            busy: false,
            
            result: [],
            result_fields: [
                { key: 'name', label: 'Система', sortable: true, thStyle: {"min-width":"300px"} },
                { key: 'subtype', label: 'Тип', sortable: true, thStyle: {"min-width":"300px"} },
                { key: 'power', label: 'Сила', sortable: true, thStyle: {"min-width":"200px"} },
                { key: 'security', label: 'Безопасность', sortable: true },
                { key: 'states', label: 'Состояния' },
                
                { key: 'updated', label: 'Обновлено', sortable: true, thStyle: {width:"100px"},
                  formatter: value => {
                      return moment.unix(value).fromNow(true);
                  }
                },
            ],
            powers: [
              { 
                label: "Империя",
                options: ["Denton Patreus", "Zemina Torval","A. Lavigny-Duval", "Aisling Duval"]
              },
              {
                label: "Федералы",
                options: ["Felicia Winters", "Zachary Hudson"]
              },
              {
                label: "Альянс",
                options: ["Edmund Mahon"]
              },
              {
                label: "Независимые",
                options: ["Li Yong-Rui", "Yuri Grom", "Archon Delaine","Pranav Antal"]
              },
            ],
            power_states: [ 
              { value: "Controlled", text: "Контролируется" },
              { value: "HomeSystem", text: "Столица" },
              { value: "Prepared", text: "Подготовлена" },
              { value: "Turmoil", text: "Кризис" },
              { value: "Contested", text: "Оспаривается" },
              { value: "Exploited", text: "Эксплуатируется" },
              { value: "InPrepareRadius", text: "В радиусе подготовки" },
            ],
        }
    },
    
    async mounted() {
        try {
            this.favor = await WS.request('favor_systems');
        } catch (err) {
            this.$bvModal.msgBoxOk(`favor: ${err}`);
        }
    },
    methods: {
        async refresh() {
            try {
                this.options = await WS.request('system_list', { name: this.search,
                                                                 populated_only: this.populatedOnly });
            } catch (err) {
                this.$bvModal.msgBoxOk(`system_list: ${err}`);
            }
        },
        
        async doSearch() {
            this.busy = true;
            try {
                this.result = await WS.request('search_parking',
                                               { systems: this.tags.map(item => item.id),
                                                 distance: this.distance,
                                                 populated: this.populated,
                                                 has_planets: this.has_planets,
                                                 subtype: this.subtype,
                                                 station: this.station,
                                                 services: this.services,
                                                 distance2star: this.distance2star,
                                               });
                this.result.forEach((item) => {
                    Vue.set(item,'_showDetails', false);
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
            this.busy = false;
        },
        onClick(item) {
            item._showDetails = !item._showDetails;
        },
        async removeTag(opt) {
            let tags = this.tags.map(item => item.id);
            let ind = tags.indexOf(opt);
            if (ind >= 0) {
                tags.splice(ind,1);
            }
            try {
                this.tags = await WS.request('calc_distances', tags);
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        async onOptionClick(opt) {
            let tags = this.tags.map(item => item.id)
            tags.push(opt);
            try {
                this.tags = await WS.request('calc_distances', tags);
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        copyClipboard(value) {
          navigator.clipboard.writeText(value);
        },
        // systemById(id) {
        //     let result = this.systems.find(item => item.value == id)
        //     return result.name;
        // },
        num2text(value) {
            // return new Intl.NumberFormat("ru", {notation:'scientific'}).format(value);
            if (value === null) return '--';
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return value.toFixed(2);
            }
        },
    },
    filters: {
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
        bool2text(value) {
            return value ? 'Да' : 'Нет';
        }
    },
}
</script>

<style>
/*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
.maincontainer {
    height: 100vh;
}
.ddcontainer {
    overflow-y: scroll;
    max-height: calc(100vh - 150px);
}
</style>
